import Input from '../../components/Input'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React, { useEffect, useState, ChangeEvent, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '@/redux/store'
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import axios from 'axios'
import {
  createProperty,
  getPropertiesId,
  editProperties,
  propertyUpload,
  lifecycleStages,
  addAddress,
  selectPrimaryOwner,
  keyAccountManager,
  getChannelList,
  addChannel,
  getPropertyAddressArea,
  businessModels,
  organizationModels,
  addJotformPropertyMapping,
  getJotformAccount,
  createContact,
  getsingleContract,
  editContactbyId,
  contractPdf,
} from '../../redux/features/propertySlice'
import styles from '../user/user.module.scss'
import { makeStyles } from '@material-ui/core/styles'
import MultiSelectWithInput from '../../components/MultipleSelectWithInput'
import Dropdown from '../../components/Dropdown'
import { ToastContainer } from 'react-toastify'
import BreadcrumbsComponent from '../../components/Breadcrums/Breadcrums'
import generateConfig, { Config } from '../../components/HelmetConfig'
import { Helmet } from 'react-helmet'
import MultipleSelect from '../../components/MultipleSelect'
import { FormControl, FormHelperText, InputLabel, MenuItem, FormControlLabel, Switch, TextField, InputAdornment, IconButton } from '@mui/material'
import Select from '@mui/material/Select';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers";
import { differenceInMonths } from 'date-fns';
import { Close as CloseIcon } from '@mui/icons-material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SingleImageUploader from '../../components/SingleImageUpload'
import { instance } from '../../instance/axios'



const multipleSelectFields = {
  coOwners: [],
}

const inputFields = {
  name: '',
  code: '',
  city: '',
  ownerId: '',
  googleMapsLocation: '',
  pincode: '',
  id: '',
  virtualTourUrl: '',
  maxOccupancy: '',
  maxAdult: '',
  maxChildren: '',
  dailyJotfromId: '',
  weeklyJotfromId: '',
  monthlyJotfromId: '',
  halfYearlyJotfromId: '',
  yearlyJotfromId: '',
  propertySortOrder: '',
}

export const propertyTheme = createTheme({
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: 'none',
          // backgroundColor:'yellow'
        },
      },
    },
  },
})

const useStyles = makeStyles((theme) => ({
  dropzone: {
    border: `2px dashed ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    padding: theme.spacing(2),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  imageWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    marginTop: theme.spacing(2),
    flexDirection: 'row',
  },
  image: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxWidth: 200,
    maxHeight: 200,
  },
}))

const theme = createTheme()

export default function PropertiesForm() {
  const [multiselectValues, setMultiselectValues] = useState<any>(multipleSelectFields)

  const [pageTitle, setPageTitle] = useState('Add Property')
  const config: Config = generateConfig(pageTitle)
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const isModalOpen = useSelector((state: any) => state?.property?.isModalOpen)
  const newDetailsId = useSelector((state: any) => state?.property?.propertyId)
  const stages: any = useSelector((state: any) => state?.property?.lifecycleStage)
  const primaryOwner: any = useSelector((state: any) => state?.property?.primaryOwner)
  const accountManager: any = useSelector((state: any) => state?.property?.accountManager)
  const travellAgentList: any = useSelector((state: any) => state?.property?.getChannelList)
  const areaList: any = useSelector((state: any) => state?.property?.propertyAddressAreaList)
  const businessList = useSelector((state: any) => state?.property?.businessModelList)
  const organizationList = useSelector((state: any) => state?.property?.organizationModelList)

  const [fields, setInputFields] = useState<any>(inputFields)
  const [images, setImages] = useState<File[]>([])
  const [selectedChannel, setSelectedChannel] = useState<string[]>([])
  const [travellAgentValues, setSelectedChannelValues] = useState<string[]>([])
  const [accountJotform, setAccountJotform] = useState<any>([])
  const [formData, setFormData] = useState({
    stageIndex: '',
    primaryOwn: '',
    keyAccountMana: '',
    businessModel: '',
    area: '',
    organizationModel: '',
    organizationName: '',
  })
  const [addAressSectionOpen, setAddressesSectionOpen] = useState(false)
  const [showEditModules, setShowEditModules] = useState<any>('')
  const [image, setImage] = useState<File | null>(null)
  const [deployedPdf, setdeployedPdf] = useState<any>('')
  const [contractDisabled, setcontractDissabled] = useState<boolean>(false)
  //Errors
  const [errors, setErrors] = useState<any>({})
  const [jotAccountName, setJotAccountName] = React.useState<any>('');
  const [propertyType, setPropertyType] = React.useState<any>(1);
  const [enableContract, setEnableContract] = React.useState<any>(false);
  const [securiy, setSecurity] =  React.useState<number | null>(null);

  const [signDate, setSignDate] = useState<Date | null>(null); 
   const propertyEnum = ['ELIVAAS','ALAYA','PRIVE']

   const [pdfData, setPdfData] = useState<any>('')
  const handleFormTypeChange = (event:any) => {
    // setFormType(event.target.value);
  };
  const [toggleOn, setToggleOn] = useState(false)
  const [lockInPeriod, setLockInPeriod] = useState<number | ''>('');
  const [penalty, setPenalty] = useState<number | ''>(200000);
  const [freeNights, setFreeNights] = useState<number | ''>('');

  const [contractformData, setContractFormData] = useState<any>({
    lockInPeriod: '',
    penalty: 200000,
    freeNights: '',
    signDate: null,
    startDate: null,
    expireDate: null
  })

  
  const handleDownload = async () => {
    // try {
    //   const response = await fetch(contractUrl);
    //   const blob = await response.blob();
    //   const url = window.URL.createObjectURL(blob);
    //   const link = document.createElement('a');
    //   link.href = url;
    //   link.download = fileName;
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    //   window.URL.revokeObjectURL(url);
    // } catch (error) {
    //   console.error('Error downloading file:', error);
    //   // Handle error appropriately
    // }
  };

  const handleContractChange = (e:any) => {
    const name = e.target.name
    const value = parseInt(e.target.value);
    console.log(value)
     if(!isNaN(value)){
      setContractFormData((prev)=>{
        return {
          ...prev,
          [name]: Math.abs(value)
        }
      })

      setErrors((prev)=>{
        return {
          ...prev,
          [name]: ''
        }
      })
     } else{
      setContractFormData((prev)=>{
        return {
          ...prev,
          [name]: ''
        }
      })
     }
  };

 
  const handleAddressSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (handleAddressValidation()) {
      const id =
        JSON.parse(localStorage.getItem('propertyId') || 'null') || location?.pathname.split('/').filter(Boolean).pop()
      let addressData = {
        id: id,
        area: formData.area,
        pincode: fields.pincode,
        city: fields.city,
      }
      dispatch(addAddress(addressData))
        .then((response: any) => {
          if (response?.payload === true) {
            navigate(`/properties/${id}/details`)
          }
        })
        .catch((error: any) => {
          // Handle the error in case the dispatch or promise fails
        })
    }
  }

  const handleJotfromMappingSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (handleJotfromMappingValidation()) {
      const id =
        JSON.parse(localStorage.getItem('propertyId') || 'null') || location?.pathname.split('/').filter(Boolean).pop()
      let jotfromPropertyMappingBody = {
        property_id: id,
        daily_jotform_form_id: fields.dailyJotfromId,
        weekly_jotform_form_id: fields.weeklyJotfromId,
        monthly_jotform_form_id: fields.monthlyJotfromId,
        half_yearly_jotform_form_id: fields.halfYearlyJotfromId,
        yearly_jotform_form_id: fields.yearlyJotfromId,
        jotform_account:jotAccountName
      }

      console.log('jotfromPropertyMappingBody', jotfromPropertyMappingBody)
      dispatch(addJotformPropertyMapping(jotfromPropertyMappingBody))
        .then((response: any) => {
          if (response?.payload === true) {
            navigate(`/properties/${id}/details`)
          }
        })
        .catch((error: any) => {
          // Handle the error in case the dispatch or promise fails
        })
    }
  }

  const handlePropertySubmit = (event: React.FormEvent<HTMLFormElement>) => {
    let propertyId = location?.pathname.split('/').filter(Boolean).pop()
    event.preventDefault()
    if (handleValidation()) {
      let propertyData = {
        name: fields.name,
        code: fields.code,
        primary_owner_id: parseInt(formData.primaryOwn),
        lifecycle_stage_id: parseInt(formData.stageIndex),
        key_account_manager_id: parseInt(formData.keyAccountMana),
        business_model_id: parseInt(formData.businessModel),
        organization_id: parseInt(formData.organizationModel),
        virtual_tour_url: fields.virtualTourUrl,
        max_occupancy: fields.maxOccupancy ? parseInt(fields.maxOccupancy) : null,
        max_adult: fields.maxAdult ? parseInt(fields.maxAdult) : null,
        max_children: fields.maxChildren ? parseInt(fields.maxChildren) : null,
        property_order: fields.propertySortOrder ? parseInt(fields.propertySortOrder) : null,
        slug: fields.propertySlug ,
        is_owner_x_sell: toggleOn,
        is_contract_enabled:enableContract,
        domain_name: parseInt(propertyType),
        security_deposit:securiy
      }

      if (propertyId === 'add') {

        const expiryDate = new Date( contractformData.expireDate).setHours(0,0,0,0)
        const signingDate = new Date(contractformData.signDate).setHours(0,0,0,0)
        const startDate = new Date(contractformData.startDate).setHours(0,0,0,0)

        console.log('formate now',format(signingDate, 'yyyy-MM-dd'))
        
        if((expiryDate <= signingDate)||(expiryDate <= startDate)){
          console.log(22)
          toast.error('Expiry date should be greater than signing date and start date')
          return
        }

        const monthDifference = differenceInMonths(expiryDate, startDate);
       
        if(contractformData.lockInPeriod>monthDifference){
        
          toast.error('Lock-in period must not exceed the duration between the start date and the expiry date.')
          return
        }

        const mergedDate = {
          ...propertyData,
          contract_signing_date: format(signingDate, 'yyyy-MM-dd'),
          contract_start_date: format(startDate, 'yyyy-MM-dd'),
          contract_end_date: format(expiryDate, 'yyyy-MM-dd'),
          free_booking_nights: contractformData.freeNights,
          lock_in_period: contractformData.lockInPeriod,
          contract_cancellation_penalty: contractformData.penalty
        }
        
        dispatch(createProperty(mergedDate)).then((response: any) => {
          if (response.payload === true) {
            let propertyId = localStorage.getItem('propertyId')
            navigate(`/properties/${propertyId}/details`)
            // setAddressesSectionOpen(true)
          }
        })
      } else {
        let editId = { id: fields.id }
        propertyData = Object.assign({}, propertyData, editId)
        dispatch(editProperties(propertyData)).then((response: any) => {
          if (response?.type === 'editProperties/fulfilled') {
            navigate(`/properties/${propertyId}/details`)
          }
        })
      }
    }
  }

  useEffect(()=>{
    if(showEditModules === 'editcontract' && image){
      dispatch(contractPdf({})).then((response: any) => {
        if (response?.type === 'contractPdf/fulfilled') {
         console.log('response contract pdf',response?.payload)
         setPdfData(response?.payload)
         if (response?.payload?.presignedPutUrl && image) {
        
          axios
            .put(response?.payload?.presignedPutUrl, image, {
              headers: {
                'Content-Type': image.type,
               
              },
            })
            .then((axiosResponse: any) => {
              // Handle the response
              console.log('Axios POST response:', axiosResponse)
            })
            .catch((error: any) => {
              // Handle the error
              console.error('Axios POST error:', error)
            })
        }
          // setAddressesSectionOpen(true)
        }
      })
    }

  },[showEditModules, image])

  console.log('pdf data',pdfData)

  const handleContractSubmit=async(event)=>{
    let propertyId = location?.pathname.split('/').filter(Boolean).pop()
    event.preventDefault()
    if (!handleContractValidation()) {
     return;
    }
    const expiryDate = new Date( contractformData.expireDate).setHours(0,0,0,0)
    const signingDate = new Date(contractformData.signDate).setHours(0,0,0,0)
    const startDate = new Date(contractformData.startDate).setHours(0,0,0,0)

   
    
    if((expiryDate <= signingDate)||(expiryDate <= startDate)){
      console.log(22)
      toast.error('Expiry date should be greater than signing date and start date')
      return
    }

    const monthDifference = differenceInMonths(expiryDate, startDate);
   
    if(contractformData.lockInPeriod>monthDifference){
    
      toast.error('Lock-in period must not exceed the duration between the start date and the expiry date.')
      return
    }

    const mergedData = {
      contract_sign_date: format(signingDate, 'yyyy-MM-dd'),
      contract_start_date: format(startDate, 'yyyy-MM-dd'),
      contract_end_date: format(expiryDate, 'yyyy-MM-dd'),
      free_booking_nights: contractformData.freeNights,
      lock_in_period: contractformData.lockInPeriod,
      contract_cancellation_penalty: contractformData.penalty
    }
    if(showEditModules === 'addcontract'){
      // dispatch(createContact({mergedData,propertyId})).then((response: any) => {
      //   if (response?.type === 'createContact/fulfilled') {
      //     let propertyId = localStorage.getItem('propertyId')
      //     navigate(`/properties/${propertyId}/details`)
      //     // setAddressesSectionOpen(true)
      //   }
      // }).catch((error: any) => {
      //   console.log('error',error)
      // })

      try {
       setcontractDissabled(true)
        const id = parseInt(propertyId || '')
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const { data } = await instance({
          method: 'POST',
          url: `api/properties/${id}/contract`,
          data: mergedData,
        });
    
        let navigateid = localStorage.getItem('propertyId')     
            navigate(`/properties/${navigateid}/details`)
           setcontractDissabled(false)
          
      } catch (err : any) {
        console.log('error in createContact', err)
        toast.error(err?.response?.data?.message || err?.message || '');
        setcontractDissabled(false)
    
       
      }
    } else{
      const isEditModuleOpen = new URLSearchParams(window.location.search)
      const contractid = parseFloat(isEditModuleOpen.get('id') || '0')
      if(!contractid){
        return 
      }
      let finalData = {
        ...mergedData,
        contract_s3_url: pdfData?.property_contract
      }

      console.log('finalData',finalData)
      dispatch(editContactbyId({finalData,propertyId, contractid})).then((response: any) => {
        if (response?.type === 'editContactbyId/fulfilled') {
          let propertyId = localStorage.getItem('propertyId')
          navigate(`/properties/${propertyId}/details`)
         
        }
      })
    }
   

  }

  const handleChannelSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const id =
      JSON.parse(localStorage.getItem('propertyId') || 'null') || location?.pathname.split('/').filter(Boolean).pop()

    // Assuming you have existingData with previous data, replace it with your actual data
    const existingData: any[] = []

    // Merge the existingData with the new data (selectedChannel)
    const mergedData = [...existingData, ...selectedChannel]

    const transformedData = mergedData.map((agent: any, index: any) => {
      const travellAgentValue = travellAgentValues[index]

      if (travellAgentValue && typeof travellAgentValue === 'object') {
        // If travellAgentValue is an object (with id, url, channel_id, and name properties)
        const { url, channel_id, ...rest } = travellAgentValue
        return { ...rest, url, channel_id } // Keep the existing data and update the url and channel_id with travellAgentValue's properties
      } else if (travellAgentValue && typeof travellAgentValue === 'string') {
        // If travellAgentValue is a string (only containing the url)
        return {
          url: travellAgentValue,
          channel_id: agent.id,
        }
      } else {
        // If travellAgentValue is not defined or not an object or a string
        // Return the agent as is, without any changes
        return agent
      }
    })

    const requestData = {
      id: id,
      data: transformedData.map((item) => {
        const { name, ...rest } = item
        return rest
      }),
    }
    dispatch(addChannel(requestData)).then((response: any) => {
      if (response?.type === 'addChannel/fulfilled' && response?.payload === true) {
        navigate(`/properties/${id}/details`)
      }
    })
  }

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    e.persist()
    const { name, value } = e.currentTarget
    if (name === 'pincode' && value.length > 6) {
      return
    }
    setInputFields((prevFields: any) => ({
      ...prevFields,
      [name]: value,
    }))
    setErrors((prevErrors: any) => {
      const { [name]: removedField, ...updatedError } = prevErrors
      return updatedError
    })
  }

  const JotformAccount=()=>{
    dispatch(getJotformAccount()).then((response: any) => {
      if (response?.type === 'getJotformAccount/fulfilled') {
        setAccountJotform(response?.payload)
      }
    })
  }

  console.log('accountJotform',accountJotform)

  useEffect(() => {
    dispatch(lifecycleStages())
    dispatch(selectPrimaryOwner())
    dispatch(keyAccountManager())
    dispatch(getChannelList())
    dispatch(getPropertyAddressArea())
    dispatch(businessModels())
    dispatch(organizationModels())
  }, [])

  useEffect(()=>{
    JotformAccount()
  },[])

  useEffect(() => {
    if (images.length > 0) {
      dispatch(propertyUpload(images))
    }
  }, [images])

  useEffect(() => {
    const isEditModuleOpen = new URLSearchParams(window.location.search)
    const showValue = isEditModuleOpen.get('show')
  
    setShowEditModules(showValue)
    const propertiesAddForm = location?.pathname.split('/').filter(Boolean).pop()
    const propertyId = propertiesAddForm || newDetailsId || localStorage.getItem('propertyId')

    if (propertyId !== 'add') {
      dispatch(getPropertiesId(propertyId)).then((response: any) => {
        console.log('kkkk', response)
        if (response?.type === 'getPropertiesId/fulfilled') {
          const property = response.payload.properties[0]
          const {
            code,
            name,
            id,
            Address,
            lifecycle_stage_id,
            primary_owner_id,
            key_account_manager_id,
            PropertyBusinessModelId,
            organizationModel,
            virtual_tour_url,
            max_occupancy,
            max_adult,
            max_children,
            PropertyJotformMapping,
            property_order,
              slug
          } = property || {}

          setInputFields({
            code: code || 'N/A',
            name: name || 'N/A',
            id: id || 'N/A',
            city: Address?.city || 'N/A',
            pincode: Address?.pincode || 'N/A',
            virtualTourUrl: virtual_tour_url || null,
            maxOccupancy: max_occupancy || null,
            maxAdult: max_adult || null,
            maxChildren: max_children || null,
            dailyJotfromId: PropertyJotformMapping?.daily_jotform_form_id || null,
            weeklyJotfromId: PropertyJotformMapping?.weekly_jotform_form_id || null,
            monthlyJotfromId: PropertyJotformMapping?.monthly_jotform_form_id || null,
            halfYearlyJotfromId: PropertyJotformMapping?.half_yearly_jotform_form_id || null,
            yearlyJotfromId: PropertyJotformMapping?.yearly_jotform_form_id || null,
            propertySortOrder: property_order || 'N/A',
            propertySlug: slug || 'N/A',
          })

          setToggleOn(property?.is_owner_x_sell)
          setSecurity(property?.security_deposit || null)
          setEnableContract(property?.is_contract_enabled)
          const propertyType = property?.domain_name?.toUpperCase();
          console.log('propertyType', propertyType)
          const getindexPropertytype = propertyEnum.findIndex((item: any) => item == propertyType);
          console.log('getindexPropertytype', getindexPropertytype)
          if(getindexPropertytype !== -1){
            setPropertyType(getindexPropertytype+1)
          }
         
          setFormData((prevFormData: any) => ({
            ...prevFormData,
            stageIndex: lifecycle_stage_id || 'N/A',
            primaryOwn: primary_owner_id || 'N/A',
            keyAccountMana: key_account_manager_id || 'N/A',
            area: Address?.area || 'N/A',
            businessModel: PropertyBusinessModelId || 'N/A',
            organizationModel: organizationModel || 'N/A',
          }))

          let travellAgentData = response?.payload?.properties[0]?.PropertyChannels?.map((agent: any) => {
            const { Channel, ...rest } = agent
            return {
              ...rest,
              name: Channel?.name || '',
            }
          })
          setPageTitle(`${response?.payload?.properties[0]?.id} - ${response?.payload?.properties[0]?.name} - Edit`)
          setSelectedChannel(travellAgentData)
          setSelectedChannelValues(travellAgentData)
          setAddressesSectionOpen(true)
          setJotAccountName(response?.payload?.properties?.[0].PropertyJotformMapping?.jotform_account)
        }
      })
    }

    if(showValue === 'editcontract'){
        const contractid = parseFloat(isEditModuleOpen.get('id') || '0');
        if(!contractid){
          return ;
        }
        let propertyId = location?.pathname.split('/').filter(Boolean).pop()
        dispatch(getsingleContract({propertyId,contractid})).then((response: any) => {
          if(response?.type === 'getsingleContract/fulfilled' ){
            console.log('single contract', response.payload)
            const contractobj = response.payload
            setContractFormData((prev) => ({
              ...prev,
              lockInPeriod: contractobj?.lock_in_period ?? '',
              penalty: contractobj?.contract_cancellation_penalty !== undefined && contractobj?.contract_cancellation_penalty !== null 
                ? parseFloat(contractobj.contract_cancellation_penalty) 
                : 200000,
              freeNights: contractobj?.free_booking_nights ?? '',
              signDate: contractobj?.contract_sign_date ? new Date(contractobj.contract_sign_date) : null,
              startDate: contractobj?.contract_start_date ? new Date(contractobj.contract_start_date) : null,
              expireDate: contractobj?.contract_end_date ? new Date(contractobj.contract_end_date) : null,
            }));
            setdeployedPdf(contractobj?.contract_s3_url?.presignedGetUrl)
            
          }
        })
        
    } else{
      setContractFormData({
        lockInPeriod: '',
        penalty: 200000,
        freeNights: '',
        signDate: null,
        startDate: null,
        expireDate: null
      })
    }
  }, [isModalOpen, location])

  console.log('erros', errors)
  console.log('contractformdata', contractformData)

  const handleValidation = (): boolean => {
    const requiredFields: string[] = ['name', 'code']
    const fieldLabels: Record<string, string> = {
      name: 'Property Name',
      code: 'Property Code',
      primaryOwn: 'Primary Owner',
      keyAccountMana: 'Key Account Manager',
      businessModel: 'Business Model',
      stageIndex: 'Stage',
      organizationModel: 'Organization',
      virtualTourUrl: 'Virtual Tour Url',
      maxOccupancy: 'Max Occupancy',
      maxAdult: 'Max Adult',
      maxChildren: 'Max Children',
      signDate:'Contract Signing Date',
      startDate:'Contract Start Date',
      expireDate:'Contract Expiry Date',
      freeNights:'Free Booking Nights Yearly',
      lockInPeriod:'Lock-in Period',
      penalty:'Contract Cancellation Penalty',
    }
    const requiredData: string[] = ['stageIndex', 'primaryOwn', 'keyAccountMana', 'businessModel']
    const errors: Record<string, string> = {}
    let formIsValid = true

    requiredFields.forEach((field) => {
      if (!fields[field]) {
        formIsValid = false
        errors[field] = `${fieldLabels[field]} Field is Required!`
      }
    })
   
   
    console.log('formData', formData)
    requiredData.forEach((data) => {
      if (!(formData as Record<string, string>)[data]) {
        formIsValid = false
        errors[data] = `${fieldLabels[data]} Field is Required!`
      }
    })

    if(showEditModules=='add'){
      Object.entries(contractformData).forEach(([key, value]) => {
        if(value === '' || value === null){
          formIsValid = false
          errors[key] = `${fieldLabels[key]} Field is Required!`
        }
      })
    }

    setErrors(errors)
    return formIsValid
  }

  const handleContractValidation = ()=>{
    const fieldLabels: Record<string, string> = {
      name: 'Property Name',
      code: 'Property Code',
      primaryOwn: 'Primary Owner',
      keyAccountMana: 'Key Account Manager',
      businessModel: 'Business Model',
      stageIndex: 'Stage',
      organizationModel: 'Organization',
      virtualTourUrl: 'Virtual Tour Url',
      maxOccupancy: 'Max Occupancy',
      maxAdult: 'Max Adult',
      maxChildren: 'Max Children',
      signDate:'Contract Signing Date',
      startDate:'Contract Start Date',
      expireDate:'Contract Expiry Date',
      freeNights:'Free Booking Nights Yearly',
      lockInPeriod:'Lock-in Period',
      penalty:'Contract Cancellation Penalty',
    }
    let formIsValid = true
    const errors: Record<string, string> = {}
    Object.entries(contractformData).forEach(([key, value]) => {
      if(value === '' || value === null){
        formIsValid = false
        errors[key] = `${fieldLabels[key]} Field is Required!`
      }
    })
    setErrors(errors)
    return formIsValid
}

  

  const handleAddressValidation = (): boolean => {
    const requiredFields: string[] = ['city', 'pincode']
    const fieldLabels: Record<string, string> = {
      pincode: 'Pincode',
      city: 'City',
    }
    const requiredData: string[] = ['area']

    const errors: Record<string, string> = {}
    let formIsValid = true

    requiredFields.forEach((field) => {
      if (!fields[field]) {
        formIsValid = false
        errors[field] = `${fieldLabels[field]} Field is Required!`
      }
    })

    if (fields.pincode && fields.pincode.length !== 6) {
      formIsValid = false
      errors.pincode = 'Pincode must have exactly 6 digits!'
    }
    console.log('formData address', formData)
    requiredData.forEach((data) => {
      if (!(formData as Record<string, string>)[data]) {
        formIsValid = false
        errors[data] = `${fieldLabels[data]} Field is Required!`
      }
    })

   

    setErrors(errors)
    return formIsValid
  }

  const handleJotfromMappingValidation = (): boolean => {
    const requiredFields: string[] = ['dailyJotfromId']
    const fieldLabels: Record<string, string> = {
      dailyJotfromId: 'DailyJotfromId',
    }

    const errors: Record<string, string> = {}
    let formIsValid = true

    requiredFields.forEach((field) => {
      console.log('field', field)
      console.log('fields', fields[field])
      console.log('!fields[field]', !fields[field])
      if (!fields[field]) {
        console.log('inner validation')
        formIsValid = false
        errors[field] = `${fieldLabels[field]} Field is Required!`
      }
    })

    if(!jotAccountName){
      formIsValid = false
      errors.jotAccountName = 'Jotform Account Name Field is Required!'
    }

    setErrors(errors)
    return formIsValid
  }

  const handleMultiSelectChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>, name: string) => {
    const { value } = event.target
    setMultiselectValues((prevState: any) => ({ ...prevState, [name]: value as string[] }))
  }

  function handleChange(event: ChangeEvent<HTMLSelectElement>) {
    const { name, value } = event.target
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: value,
    }))
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: '', // Clear the specific error field for the selected dropdown
    }))
  }
  console.log('PropertyBusinessModelId', formData.businessModel)
  console.log('Organization Id', formData.organizationModel)
  return (
    <>
      <Helmet>
        <title>{config.title}</title>
      </Helmet>
      <ToastContainer />
      <BreadcrumbsComponent />
      <ThemeProvider theme={propertyTheme}>
        <Container disableGutters component="main" maxWidth="xl" sx={{ mt: 1 }}>
          <CssBaseline />
          {showEditModules === 'basic' || showEditModules === 'add' ? (
            <>
              {' '}
              <Typography component="h1" variant="h5">
                Basic Details
              </Typography>
              <Box component="form" onSubmit={handlePropertySubmit} noValidate>
                <Grid
                  container
                  rowSpacing={0}
                  columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                  sx={{ '& .MuiGrid-item': { pt: 0 } }}
                >
                  <Grid item xs={4}>
                    <Input
                      margin="normal"
                      label="Property Name"
                      id="name"
                      name="name"
                      autoComplete=""
                      onChange={handleInputChange}
                      autoFocus
                      required
                      type="text"
                      disabled={false}
                      errorMsg={errors?.name}
                      error={errors?.name ? true : false}
                      value={fields?.name}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      margin="normal"
                      onChange={handleInputChange}
                      required
                      name="code"
                      label="Property Code"
                      type="text"
                      id="code"
                      autoComplete=""
                      disabled={false}
                      errorMsg={errors?.code}
                      error={errors?.code ? true : false}
                      value={fields?.code}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Dropdown
                      margin={0}
                      label={'Select Primary Owner'}
                      options={primaryOwner}
                      onChange={handleChange}
                      name="primaryOwn"
                      value={formData.primaryOwn}
                      errormsg={errors?.primaryOwn}
                      error={errors?.primaryOwn ? true : false}
                      className={`${styles.login_dropdown_container}`}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Dropdown
                      margin={0}
                      label={'Select Key Account Manager'}
                      options={accountManager}
                      onChange={handleChange}
                      name="keyAccountMana"
                      value={formData.keyAccountMana}
                      errormsg={errors?.keyAccountMana}
                      error={errors?.keyAccountMana ? true : false}
                      className={`${styles.login_dropdown_container}`}
                      topval={0}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Dropdown
                      margin={0}
                      label={'Select Stage'}
                      options={stages}
                      onChange={handleChange}
                      name="stageIndex"
                      value={formData.stageIndex}
                      errormsg={errors?.stageIndex}
                      error={errors?.stageIndex ? true : false}
                      className={`${styles.login_dropdown_container}`}
                      topval={0}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Dropdown
                      margin={0}
                      label={'Select Businees Model'}
                      options={businessList}
                      onChange={handleChange}
                      name="businessModel"
                      value={formData.businessModel}
                      errormsg={errors?.businessModel}
                      error={errors?.businessModel ? true : false}
                      className={`${styles.login_dropdown_container}`}
                      topval={0}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Dropdown
                      margin={0}
                      label={'Select Organization'}
                      options={organizationList}
                      onChange={handleChange}
                      name="organizationModel"
                      value={formData.organizationModel}
                      errormsg={errors?.organizationModel}
                      error={errors?.organizationModel ? true : false}
                      className={`${styles.login_dropdown_container}`}
                      topval={1}
                    />

                    {/*options?: { firstName: any; label: any; name: any; id: any }[]*/}
                    {/*value?: any*/}
                    {/*name?: any*/}
                    {/*label?: string*/}
                    {/*onChange?: any*/}
                    {/*errormsg?: string*/}
                    {/*error?: boolean*/}
                    {/*margin?: any*/}
                    {/*className?: string*/}
                    {/*<Dropdown*/}
                    {/*    margin={0}*/}
                    {/*    label={'Select Businees Model'}*/}
                    {/*    options={businessList}*/}
                    {/*    onChange={handleChange}*/}
                    {/*    name="businessModel"*/}
                    {/*    value={formData.businessModel}*/}
                    {/*    errormsg={errors?.businessModel}*/}
                    {/*    error={errors?.businessModel ? true : false}*/}
                    {/*    className={`${styles.login_dropdown_container}`}*/}
                    {/*/>*/}
                  </Grid>
                  <Grid item xs={4} mt={1}>
                    <Input
                      margin="none"
                      label="Virtual Tour Url"
                      id="virtualTourUrl"
                      name="virtualTourUrl"
                      autoComplete=""
                      onChange={handleInputChange}
                      type="text"
                      disabled={false}
                      errorMsg={errors?.virtualTourUrl}
                      error={errors?.virtualTourUrl ? true : false}
                      value={fields?.virtualTourUrl}
                    />
                  </Grid>
                  <Grid item xs={4} mt={1}>
                    <Input
                      margin="none"
                      label="Max Occupancy"
                      id="maxOccupancy"
                      name="maxOccupancy"
                      autoComplete=""
                      onChange={handleInputChange}
                      autoFocus
                      required
                      type="number"
                      disabled={false}
                      errorMsg={errors?.maxOccupancy}
                      error={errors?.maxOccupancy ? true : false}
                      value={fields?.maxOccupancy}
                    />
                  </Grid>
                  <Grid item xs={4} mt={1}>
                    <Input
                      margin="none"
                      label="Max Adults"
                      id="maxAdult"
                      name="maxAdult"
                      autoComplete=""
                      onChange={handleInputChange}
                      autoFocus
                      required
                      type="number"
                      disabled={false}
                      errorMsg={errors?.maxAdult}
                      error={errors?.maxAdult ? true : false}
                      value={fields?.maxAdult}
                    />
                  </Grid>
                  <Grid item xs={4} mt={1}>
                    <Input
                      margin="none"
                      label="Max Children"
                      id="maxChildren"
                      name="maxChildren"
                      autoComplete=""
                      onChange={handleInputChange}
                      autoFocus
                      required
                      type="number"
                      disabled={false}
                      errorMsg={errors?.maxChildren}
                      error={errors?.maxChildren ? true : false}
                      value={fields?.maxChildren}
                    />
                  </Grid>
                  {showEditModules === 'add' && (
                    <>
                      <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            closeOnSelect={false}
                            name="signDate"
                            sx={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: '8px' }}
                            value={contractformData.signDate}
                            format={'dd MMM yyyy'} // Only show format when date is selected
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                variant: 'outlined',
                                size: 'small',
                                error: Boolean(errors?.signDate),
                                helperText: errors?.signDate || '',
                                InputProps: {
                                  sx: {
                                    padding: '8px',
                                    '& input': {
                                      paddingX: '6px',
                                      '&::placeholder': {
                                        color: 'rgba(0, 0, 0, 0.6)',
                                        opacity: 1, // Firefox needs this
                                      },
                                      // For older browsers
                                      '&::-webkit-input-placeholder': {
                                        color: 'rgba(0, 0, 0, 0.6)',
                                        opacity: 1,
                                      },
                                      '&::-moz-placeholder': {
                                        color: 'rgba(0, 0, 0, 0.6)',
                                        opacity: 1,
                                      },
                                      '&:-ms-input-placeholder': {
                                        color: 'rgba(0, 0, 0, 0.6)',
                                        opacity: 1,
                                      },
                                    },
                                  },
                                },
                                FormHelperTextProps: {
                                  sx: {
                                    textAlign: 'left',
                                    marginLeft: '36px',
                                    width: '100%',
                                  },
                                },
                                placeholder: 'Contract Signing Date',
                              },
                            }}
                            onChange={(date) => {
                              setContractFormData((prev) => {
                                return {
                                  ...prev,
                                  signDate: date,
                                }
                              })

                              setErrors((prev) => {
                                return {
                                  ...prev,
                                  signDate: '',
                                }
                              })
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            closeOnSelect={false}
                            name="startDate"
                            sx={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: '8px' }}
                            value={contractformData.startDate}
                            format={'dd MMM yyyy'}
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                variant: 'outlined',
                                size: 'small',
                                InputProps: {
                                  sx: {
                                    padding: '8px',
                                    '& input': {
                                      paddingX: '6px',
                                      '&::placeholder': {
                                        color: 'rgba(0, 0, 0, 0.6)',
                                        opacity: 1, // Firefox needs this
                                      },
                                      // For older browsers
                                      '&::-webkit-input-placeholder': {
                                        color: 'rgba(0, 0, 0, 0.6)',
                                        opacity: 1,
                                      },
                                      '&::-moz-placeholder': {
                                        color: 'rgba(0, 0, 0, 0.6)',
                                        opacity: 1,
                                      },
                                      '&:-ms-input-placeholder': {
                                        color: 'rgba(0, 0, 0, 0.6)',
                                        opacity: 1,
                                      },
                                    },
                                  },
                                },
                                FormHelperTextProps: {
                                  sx: {
                                    textAlign: 'left',
                                    marginLeft: '36px',
                                    width: '100%',
                                  },
                                },
                                error: Boolean(errors?.startDate),
                                helperText: errors?.startDate || '',
                                placeholder: 'Contract Start Date',
                              },
                            }}
                            onChange={(date) => {
                              const formattedDate = date ? format(new Date(date), 'yyyy-MM-dd') : ''
                              console.log('formattedDate', formattedDate)
                              setContractFormData((prev) => ({
                                ...prev,
                                startDate: date, // Store the formatted date as a string
                              }))

                              setErrors((prev) => {
                                return {
                                  ...prev,
                                  startDate: '',
                                }
                              })
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            closeOnSelect={false}
                            name="expireDate"
                            sx={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: '8px' }}
                            value={contractformData.expireDate}
                            format={'dd MMM yyyy'}
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                variant: 'outlined',
                                size: 'small',
                                InputProps: {
                                  sx: {
                                    padding: '8px',
                                    '& input': {
                                      paddingX: '6px',
                                      '&::placeholder': {
                                        color: 'rgba(0, 0, 0, 0.6)',
                                        opacity: 1, // Firefox needs this
                                      },
                                      // For older browsers
                                      '&::-webkit-input-placeholder': {
                                        color: 'rgba(0, 0, 0, 0.6)',
                                        opacity: 1,
                                      },
                                      '&::-moz-placeholder': {
                                        color: 'rgba(0, 0, 0, 0.6)',
                                        opacity: 1,
                                      },
                                      '&:-ms-input-placeholder': {
                                        color: 'rgba(0, 0, 0, 0.6)',
                                        opacity: 1,
                                      },
                                    },
                                  },
                                },
                                FormHelperTextProps: {
                                  sx: {
                                    textAlign: 'left',
                                    marginLeft: '36px',
                                    width: '100%',
                                  },
                                },
                                error: Boolean(errors?.expireDate),
                                helperText: errors?.expireDate || '',
                                placeholder: 'Contract Expiry Date',
                              },
                            }}
                            onChange={(date) => {
                              setContractFormData((prev) => {
                                return {
                                  ...prev,
                                  expireDate: date,
                                }
                              })
                              setErrors((prev) => {
                                return {
                                  ...prev,
                                  expireDate: '',
                                }
                              })
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={4} mt={1}>
                        <TextField
                          margin="none"
                          fullWidth
                          label="Free Booking Nights Yearly"
                          id="freeNights"
                          name="freeNights"
                          autoComplete="off"
                          autoFocus
                          required
                          type="number"
                          value={contractformData.freeNights} // Bound to state
                          onChange={handleContractChange}
                          InputProps={{
                            inputProps: { min: 0 },
                          }}
                          error={errors?.freeNights ? true : false}
                          helperText={errors?.freeNights || ''}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          margin="normal"
                          fullWidth
                          label="Lock-in Period"
                          id="lockInPeriod"
                          name="lockInPeriod"
                          autoComplete="off"
                          autoFocus
                          required
                          value={contractformData.lockInPeriod}
                          type="number"
                          onChange={handleContractChange}
                          error={errors?.lockInPeriod ? true : false}
                          helperText={errors?.lockInPeriod || ''}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">months</InputAdornment>,
                            inputProps: { min: 0 },
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          margin="normal"
                          fullWidth
                          label="Contract Cancellation Penalty"
                          id="penalty"
                          name="penalty"
                          autoComplete="off"
                          autoFocus
                          required
                          type="number"
                          value={contractformData.penalty} // Bound to state
                          onChange={handleContractChange}
                          InputProps={{
                            inputProps: { min: 0 },
                          }}
                          error={errors?.penalty ? true : false}
                          helperText={errors?.penalty || ''}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={4}>
                    <Input
                      margin="normal"
                      label="Property Website Sorting Order"
                      id="maxChildren"
                      name="propertySortOrder"
                      autoComplete=""
                      onChange={handleInputChange}
                      autoFocus
                      type="number"
                      disabled={false}
                      errorMsg={errors?.propertySortOrder}
                      error={errors?.propertySortOrder ? true : false}
                      value={fields?.propertySortOrder}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      '& > div': {
                        marginTop: '4px', // Adjust margin as needed
                      },
                    }}
                  >
                    <Input
                      margin="dense"
                      label="Property Slug"
                      id="propertySlug"
                      name="propertySlug"
                      autoComplete=""
                      onChange={handleInputChange}
                      autoFocus
                      type="text"
                      disabled={false}
                      errorMsg={errors?.propertySlug}
                      error={errors?.propertySlug ? true : false}
                      value={fields?.propertySlug}
                    />
                  </Grid>
                  <Grid item xs={4} mt={1}>
                    <Input
                      margin="none"
                      label="Security Deposit"
                      id="securityDeposit"
                      name="securityDeposit"
                      autoComplete=""
                      value={securiy}
                      onChange={(e:any)=>{
                        setSecurity(e.target.value)
                      }}
                      autoFocus
                    
                      type="number"
                      disabled={false}
                      
                    />
                  </Grid>

                  <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <FormControl fullWidth>
                      <InputLabel id="brand-select-label">Brand Name</InputLabel>
                      <Select
                        labelId="brand-select-label"
                        id="brand-account-select"
                        value={propertyType}
                        label="Brand Name"
                        onChange={(e) => {
                          setPropertyType(e.target.value)
                        }}
                      >
                        {propertyEnum?.map((item: any, index: any) => {
                          return <MenuItem value={index + 1}>{item}</MenuItem>
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                    <FormControlLabel
                      control={<Switch checked={toggleOn} name="ownerCrossSellAllowed" />}
                      onChange={() => {
                        setToggleOn(!toggleOn)
                      }}
                      label="Owner Cross Sell Allowed"
                    />
                  </Grid>
                  <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                    <FormControlLabel
                      control={<Switch checked={enableContract} name="enableContract" />}
                      onChange={() => {
                        setEnableContract(!enableContract)
                      }}
                      label="Enable Contract"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, mb: 2, width: 150 }}>
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : (
            ''
          )}

          {(showEditModules === 'addcontract' || showEditModules === 'editcontract') && (
            <>
              <hr style={{ margin: '20px 0' }} />
              <Typography component="h1" variant="h5">
                Contract Details
              </Typography>
              <Box component="form" onSubmit={handleContractSubmit} noValidate sx={{ mt: 1 }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        closeOnSelect={false}
                        name="signDate"
                        sx={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: '8px' }}
                        value={contractformData.signDate}
                        format={'dd MMM yyyy'} // Only show format when date is selected
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            variant: 'outlined',
                            size: 'small',
                            error: Boolean(errors?.signDate),
                            helperText: errors?.signDate || '',
                            InputProps: {
                              sx: {
                                padding: '8px',
                                '& input': {
                                  paddingX: '6px',
                                  '&::placeholder': {
                                    color: 'rgba(0, 0, 0, 0.6)',
                                    opacity: 1, // Firefox needs this
                                  },
                                  // For older browsers
                                  '&::-webkit-input-placeholder': {
                                    color: 'rgba(0, 0, 0, 0.6)',
                                    opacity: 1,
                                  },
                                  '&::-moz-placeholder': {
                                    color: 'rgba(0, 0, 0, 0.6)',
                                    opacity: 1,
                                  },
                                  '&:-ms-input-placeholder': {
                                    color: 'rgba(0, 0, 0, 0.6)',
                                    opacity: 1,
                                  },
                                },
                              },
                            },
                            FormHelperTextProps: {
                              sx: {
                                textAlign: 'left',
                                marginLeft: '36px',
                                width: '100%',
                              },
                            },
                            placeholder: 'Contract Signing Date',
                          },
                        }}
                        onChange={(date) => {
                          setContractFormData((prev) => {
                            return {
                              ...prev,
                              signDate: date,
                            }
                          })

                          setErrors((prev) => {
                            return {
                              ...prev,
                              signDate: '',
                            }
                          })
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        closeOnSelect={false}
                        name="startDate"
                        sx={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: '8px' }}
                        value={contractformData.startDate}
                        format={'dd MMM yyyy'}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            variant: 'outlined',
                            size: 'small',
                            InputProps: {
                              sx: {
                                padding: '8px',
                                '& input': {
                                  paddingX: '6px',
                                  '&::placeholder': {
                                    color: 'rgba(0, 0, 0, 0.6)',
                                    opacity: 1, // Firefox needs this
                                  },
                                  // For older browsers
                                  '&::-webkit-input-placeholder': {
                                    color: 'rgba(0, 0, 0, 0.6)',
                                    opacity: 1,
                                  },
                                  '&::-moz-placeholder': {
                                    color: 'rgba(0, 0, 0, 0.6)',
                                    opacity: 1,
                                  },
                                  '&:-ms-input-placeholder': {
                                    color: 'rgba(0, 0, 0, 0.6)',
                                    opacity: 1,
                                  },
                                },
                              },
                            },
                            FormHelperTextProps: {
                              sx: {
                                textAlign: 'left',
                                marginLeft: '36px',
                                width: '100%',
                              },
                            },
                            error: Boolean(errors?.startDate),
                            helperText: errors?.startDate || '',
                            placeholder: 'Contract Start Date',
                          },
                        }}
                        onChange={(date) => {
                          const formattedDate = date ? format(new Date(date), 'yyyy-MM-dd') : ''
                          console.log('formattedDate', formattedDate)
                          setContractFormData((prev) => ({
                            ...prev,
                            startDate: date, // Store the formatted date as a string
                          }))

                          setErrors((prev) => {
                            return {
                              ...prev,
                              startDate: '',
                            }
                          })
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        closeOnSelect={false}
                        name="expireDate"
                        sx={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: '8px' }}
                        value={contractformData.expireDate}
                        format={'dd MMM yyyy'}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            variant: 'outlined',
                            size: 'small',
                            InputProps: {
                              sx: {
                                padding: '8px',
                                '& input': {
                                  paddingX: '6px',
                                  '&::placeholder': {
                                    color: 'rgba(0, 0, 0, 0.6)',
                                    opacity: 1, // Firefox needs this
                                  },
                                  // For older browsers
                                  '&::-webkit-input-placeholder': {
                                    color: 'rgba(0, 0, 0, 0.6)',
                                    opacity: 1,
                                  },
                                  '&::-moz-placeholder': {
                                    color: 'rgba(0, 0, 0, 0.6)',
                                    opacity: 1,
                                  },
                                  '&:-ms-input-placeholder': {
                                    color: 'rgba(0, 0, 0, 0.6)',
                                    opacity: 1,
                                  },
                                },
                              },
                            },
                            FormHelperTextProps: {
                              sx: {
                                textAlign: 'left',
                                marginLeft: '36px',
                                width: '100%',
                              },
                            },
                            error: Boolean(errors?.expireDate),
                            helperText: errors?.expireDate || '',
                            placeholder: 'Contract Expiry Date',
                          },
                        }}
                        onChange={(date) => {
                          setContractFormData((prev) => {
                            return {
                              ...prev,
                              expireDate: date,
                            }
                          })
                          setErrors((prev) => {
                            return {
                              ...prev,
                              expireDate: '',
                            }
                          })
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      margin="normal"
                      fullWidth
                      label="Free Booking Nights Yearly"
                      id="freeNights"
                      name="freeNights"
                      autoComplete="off"
                      autoFocus
                      required
                      type="number"
                      value={contractformData.freeNights} // Bound to state
                      onChange={handleContractChange}
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                      error={errors?.freeNights ? true : false}
                      helperText={errors?.freeNights || ''}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      margin="normal"
                      fullWidth
                      label="Lock-in Period"
                      id="lockInPeriod"
                      name="lockInPeriod"
                      autoComplete="off"
                      autoFocus
                      required
                      value={contractformData.lockInPeriod}
                      type="number"
                      onChange={handleContractChange}
                      error={errors?.lockInPeriod ? true : false}
                      helperText={errors?.lockInPeriod || ''}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">months</InputAdornment>,
                        inputProps: { min: 0 },
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      margin="normal"
                      fullWidth
                      label="Contract Cancellation Penalty"
                      id="penalty"
                      name="penalty"
                      autoComplete="off"
                      autoFocus
                      required
                      type="number"
                      value={contractformData.penalty} // Bound to state
                      onChange={handleContractChange}
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                      error={errors?.penalty ? true : false}
                      helperText={errors?.penalty || ''}
                    />
                  </Grid>

                  {
                    showEditModules === 'editcontract' && 
                    <Grid item xs={4}>
                    <SingleImageUploader
                      image={image}
                      setImage={setImage}
                      deployedpdf = {deployedPdf}
                      // errorMsg={errors?.image}
                      // error={errors?.image ? true : false}
                    />
                  </Grid>

                  }

               

                  <Grid item xs={4}>
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, mb: 2, width: 150 }}
                     disabled={contractDisabled}
                     
                    >
                     {contractDisabled
            ? 'Submitting ...' 
            : showEditModules === 'addcontract' 
                ? 'Submit' 
                : 'Update'
        }
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
          {(showEditModules === 'add' && addAressSectionOpen === true) || showEditModules === 'address' ? (
            <>
              <hr style={{ margin: '20px 0' }} />
              <Typography component="h1" variant="h5">
                Address Details
              </Typography>
              <Box component="form" onSubmit={handleAddressSubmit} noValidate sx={{ mt: 1 }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={4}>
                    <Input
                      margin="normal"
                      label="City"
                      id="city"
                      name="city"
                      autoComplete=""
                      onChange={handleInputChange}
                      autoFocus
                      required
                      type="text"
                      disabled={false}
                      errorMsg={errors?.city}
                      error={errors?.city ? true : false}
                      value={fields?.city}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Dropdown
                      margin={0}
                      label={'Select Area'}
                      options={areaList}
                      onChange={handleChange}
                      name="area"
                      value={formData.area}
                      errormsg={errors?.area}
                      error={errors?.area ? true : false}
                      className={`${styles.login_dropdown_container}`}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      margin="normal"
                      label="Pincode"
                      id="pincode"
                      name="pincode"
                      autoComplete=""
                      onChange={handleInputChange}
                      autoFocus
                      required
                      type="number"
                      disabled={false}
                      errorMsg={errors?.pincode}
                      error={errors?.pincode ? true : false}
                      value={fields?.pincode}
                    />
                  </Grid>
                  <Grid item container direction="row" sx={{ mt: 1 }} justifyContent="space-between" xs={12}>
                    <Button type="submit" fullWidth variant="contained" sx={{ width: 150 }}>
                      Submit
                    </Button>

                    {/* <Link
                      color="primary"
                      sx={{ width: 150, whiteSpace: 'nowrap', cursor: 'pointer' }}
                      onClick={goToProperty}
                    >
                      Goto Property Listing
                    </Link> */}
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : (
            ''
          )}
          {(showEditModules === 'add' && addAressSectionOpen === true) || showEditModules === 'channel' ? (
            <>
              <hr style={{ margin: '20px 0' }} />
              <Typography component="h1" variant="h5">
                Channel
              </Typography>
              <Box component="form" onSubmit={handleChannelSubmit} noValidate sx={{ mt: 1 }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={12}>
                    <MultiSelectWithInput
                      label={'Select Channel'}
                      options={travellAgentList}
                      selectedItems={selectedChannel}
                      setSelectedItems={setSelectedChannel}
                      itemValues={travellAgentValues}
                      setItemValues={setSelectedChannelValues}
                      subLabelText={'Url'}
                    />
                  </Grid>
                  <Grid item container direction="row" sx={{ mt: 1 }} justifyContent="space-between" xs={12}>
                    <Button type="submit" fullWidth variant="contained" sx={{ width: 150 }}>
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : (
            ''
          )}
          {(showEditModules === 'add' && addAressSectionOpen === true) || showEditModules === 'jotformMapping' ? (
            <>
              <hr style={{ margin: '20px 0' }} />
              <Typography component="h1" variant="h5">
                Property Jotform Mapping
              </Typography>
              <Box component="form" onSubmit={handleJotfromMappingSubmit} noValidate sx={{ mt: 1 }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 1 }}>
                    <FormControl fullWidth error={errors?.jotAccountName ? true : false}>
                      <InputLabel id="demo-simple-select-label">Jotform Accounts</InputLabel>
                      <Select
                        labelId="jotform-account-select-label"
                        id="jotform-account-select"
                        value={jotAccountName}
                        label="Jotform Accounts"
                        onChange={(e) => {
                          setJotAccountName(e.target.value)
                          setErrors((prev: any) => {
                            return { ...prev, jotAccountName: '' }
                          })
                        }}
                      >
                        {accountJotform?.map((item: any, index: any) => {
                          return <MenuItem value={item}>{item}</MenuItem>
                        })}
                      </Select>
                      {errors?.jotAccountName && <FormHelperText>{errors.jotAccountName}</FormHelperText>}
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      margin="normal"
                      label="Daily Form ID"
                      id="dailyJotfromId"
                      name="dailyJotfromId"
                      autoComplete=""
                      onChange={handleInputChange}
                      autoFocus
                      required
                      type="text"
                      disabled={false}
                      errorMsg={errors?.dailyJotfromId}
                      error={errors?.dailyJotfromId ? true : false}
                      value={fields?.dailyJotfromId}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      margin="normal"
                      label="Weekly Form Id"
                      id="weeklyJotfromId"
                      name="weeklyJotfromId"
                      autoComplete=""
                      onChange={handleInputChange}
                      autoFocus
                      required
                      type="text"
                      disabled={false}
                      errorMsg={errors?.weeklyJotfromId}
                      error={errors?.weeklyJotfromId ? true : false}
                      value={fields?.weeklyJotfromId}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      margin="normal"
                      label="Monthly Form ID"
                      id="monthlyJotfromId"
                      name="monthlyJotfromId"
                      autoComplete=""
                      onChange={handleInputChange}
                      autoFocus
                      required
                      type="text"
                      disabled={false}
                      errorMsg={errors?.monthlyJotfromId}
                      error={errors?.monthlyJotfromId ? true : false}
                      value={fields?.monthlyJotfromId}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      margin="normal"
                      label="Half Yearly Form ID"
                      id="halfYearlyJotfromId"
                      name="halfYearlyJotfromId"
                      autoComplete=""
                      onChange={handleInputChange}
                      autoFocus
                      required
                      type="text"
                      disabled={false}
                      errorMsg={errors?.halfYearlyJotfromId}
                      error={errors?.halfYearlyJotfromId ? true : false}
                      value={fields?.halfYearlyJotfromId}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      margin="normal"
                      label="Yearly Form ID"
                      id="yearlyJotfromId"
                      name="yearlyJotfromId"
                      autoComplete=""
                      onChange={handleInputChange}
                      autoFocus
                      required
                      type="text"
                      disabled={false}
                      errorMsg={errors?.yearlyJotfromId}
                      error={errors?.yearlyJotfromId ? true : false}
                      value={fields?.yearlyJotfromId}
                    />
                  </Grid>
                  <Grid item container direction="row" sx={{ mt: 1 }} justifyContent="space-between" xs={12}>
                    <Button type="submit" fullWidth variant="contained" sx={{ width: 150 }}>
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : (
            ''
          )}
        </Container>
      </ThemeProvider>
    </>
  )
}
